import Vue from 'vue'
import VueRouter from 'vue-router'
import lanhu_1landing from '../views/lanhu_1landing/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: "/home"
  },
  {
    path: '/home',
    name: 'lanhu_1landing',
    component: lanhu_1landing
  },
  {
    path: "/signup",
    name: "注册",
    component: () => import('../views/lanhu_1landing/Signup.vue')
  },
  {
    path: "/homeM",
    name: "home",
    component: () => import('../views/lanhu_1landing/indexM.vue')
  },
  {
    path: "/login",
    name: "登入",
    component: () => import('../views/lanhu_1landing/login.vue')
  },
  {
    path: "/link",
    name: "跳转",
    component: () => import("../views/lanhu_1landing/wechat.vue")
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  // console.log(to)
  // console.log(from)
  // alert(navigator.userAgent.toLowerCase())
  if (/QRcode/.test(to.fullPath)) {
    // console.log("")
    // alert("SAd")
    return
  } else {
    const ua = navigator.userAgent.toLowerCase()
    const isWeixin = ua.indexOf('micromessenger') !== -1
    // alert(isWeixin)
    if (isWeixin && to.path != "/link") {
      next("/link")
    } else if (!isWeixin && to.path == "/link") {
      next("/")
    }

    else {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
      if (isMobile && to.path === '/home') {
        next('/homeM')
      } else if (!isMobile && to.path === '/homeM') {
        next('/home')
      } else {
        next()
      }
    }

  }

})

router.beforeEach()
export default router
