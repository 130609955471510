<template>
  <div class="page flex-col" style="">
    <div class="box_13 flex-col">
      <span class="text_1">Documentation</span>
      <div class="section_6 flex-row">
        <span class="text_2 textcolor">福建超数科技有限公司</span>
        <span class="text_3 textcolor">&nbsp;—中国信通院《可信虚拟人白皮书》参编单位</span>
        <div v-if="!$store.state.company_name" class="text-wrapper_1 flex-col cursorp-p" @click="login">
          <span class="text_4">登录</span>
        </div>
      </div>
      <span class="text_5">基于大模型AI数字人应用</span>
      <span class="text_6">—企业级TV版</span>
      <div class="text-wrapper_2 flex-col">
        <span class="text_7">登录/注册</span>
      </div>
      <img class="image_1" referrerpolicy="no-referrer"
        src="./assets/img/MasterDDSSlicePNG04874ce2417aed49c1abe7d00523a2d9.png" />
    </div>
    <div class="box_14 flex-col">
      <div v-if="!$store.state.company_name" class="text-wrapper_3 flex-col applyfor  cursorp-p" @click="register">
        <span class="text_8 cursorp-p">免费试用申请</span>
      </div>
      <div v-else style="display: flex;">
        <div class="text-wrapper_3 flex-col  cursorp-p applyfor" @click="downloadApp">
          <span class="text_8 cursorp-p">下载app体验</span>
        </div>
        <div class="text-wrapper_3 flex-col  cursorp-p applyfor" @click="ToThird">
          <span class="text_8 cursorp-p">购买终身服务</span>
        </div>

      </div>
      <div class="block_5 flex-row">
        <div class="image-wrapper_5 flex-col">
          <img class="image_11" referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGf5d55890be620175ce0a3a553b178762.png" />
        </div>
        <div class="image-wrapper_2 flex-col">
          <img class="image_3" referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG2088b72970e247fbf7126dfdd336f530.png" />
        </div>
        <div class="box_4 flex-col">
          <img class="image_12" referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNGf209559438b2cb67621cbb4e74bdca0b.png" />
        </div>
        <div class="box_6 flex-col">
          <div class="image-wrapper_3 flex-col">
            <img class="image_4" referrerpolicy="no-referrer"
              src="./assets/img/MasterDDSSlicePNG19dcad9d6a7023633ae4cca09ac69ddf.png" />
          </div>
        </div>
      </div>
      <div class="text-wrapper_14 flex-row">
        <span class="text_11">文本驱动</span>
        <span class="text_12">语音定制</span>
        <span class="text_13">风格多样</span>
        <span class="text_14">自定义问答</span>
      </div>
      <div class="text-wrapper_15 flex-row">
        <span class="paragraph_1">支持文本驱动，<br />可输入文字控制指令</span>
        <span class="paragraph_2">内置多套服装场景<br />可根据场景需求进行更换</span>
        <span class="paragraph_3">内置自定义问答功能<br />可根据场景需求自己设置</span>
        <span class="paragraph_4">支持语音定制<br />可通过语音控制指令</span>
      </div>
      <div class="box_7 flex-col"></div>
      <div class="text-wrapper_7">
        <span class="text_15">技术架构</span>
        <span class="paragraph_5"><br />Technical&nbsp;Architecture</span>
      </div>
      <div class="box_8 flex-col justify-end">
        <!-- <div class="box_9 flex-col"></div> -->
        <div class="text-wrapper_16 flex-row">
          <span class="text_16">文字Chat</span>
          <span class="text_17">语音通话</span>
          <span class="text_18">可视化语音交互</span>
        </div>
      </div>
      <div class="text-group_3 flex-col justify-between">
        <span class="text_19">数字人角色多样化</span>
        <span class="text_20">Linguae&nbsp;digitorum&nbsp;diversae</span>
      </div>
      <div class="block_6 flex-row justify-between">
        <div class="section_1 flex-col">
          <img class="image_5" referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG4aa4107c485bcbc089dcab0ab73bb9ae.png" />
          <span class="text_21">会议主持人</span>
        </div>
        <div class="section_2 flex-col">
          <img class="image_6" referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG4aa4107c485bcbc089dcab0ab73bb9ae.png" />
          <span class="text_22">企业培训师</span>
        </div>
        <div class="section_3 flex-col">
          <img class="image_7" referrerpolicy="no-referrer"
            src="./assets/img/MasterDDSSlicePNG4aa4107c485bcbc089dcab0ab73bb9ae.png" />
          <span class="text_23">前台员工</span>
        </div>
      </div>
      <div class="text-wrapper_9 flex-col">
        <span class="text_24">联系我们</span>
      </div>
      <div class="image-wrapper_7 flex-row justify-between" style="position: relative;margin: 0 auto;margin-top: 5.5rem;">
        <el-image class="image_8" referrerpolicy="no-referrer"
          :src="require('./assets/img/MasterDDSSlicePNGce760ea35587725afd4fb2d41ade7e48.png')" :previewSrcList="srcList">
        </el-image>
        <el-image class="image_9" referrerpolicy="no-referrer"
          :src="require('./assets/img/MasterDDSSlicePNG7ce30484e08e12182379c77b8864f81a.png')" :previewSrcList="srcList">
        </el-image>

        <div style="color: #fff;position: absolute;bottom: 0;    width: 27.667rem;bottom: -40px;text-align: center;">
          备案号：<a href="https://beian.miit.gov.cn/" style="color: #fff;" target="_blank">闽ICP备2022017112号,闽公网安备35012102550458号,闽ICP备2022017112</a>
        </div>
      </div>
      <div class="text-wrapper_10">
        <span class="text_25">技术特点</span>
        <span class="paragraph_6"><br />Technical&nbsp;Architecture</span>
      </div>
      <!-- <span class="text_26">形态升级，通过可视化交互，带来全新客户体验</span> -->


    </div>
    <div class="block_3 flex-col">
      <span class="text_27 subhead">专为企业提供一站式服务，打造能说、会动、可交互的AI虚拟数字人，让企业走在元宇宙时代的前沿，让我们掌控未来，开启智能互动时代！</span>
      <video preload="auto" controls crossorigin="anonymous" poster="./assets/img/static.jpg" class="bloom-video image_10"
        id="video" src="./assets/mp4/static.mp4"></video>
    </div>


  </div>
</template>
<script>
// showConfirmDialog,
import { Dialog } from 'vant';
import { Notify } from 'vant';
export default {
  data() {
    return {
      constants: {},
      animateStatus: false,
      isVisible: false,
      text7IsVisible: false,
      M: false,
      srcList: [
        require("./assets/img/MasterDDSSlicePNGce760ea35587725afd4fb2d41ade7e48.png"),
        require("./assets/img/MasterDDSSlicePNG7ce30484e08e12182379c77b8864f81a.png")
      ],
    };
  },
  created() {
    (function flexible(window, document) {
      function resetFontSize() {
        // const size = (document.documentElement.clientWidth / 360) * 12;
        // document.documentElement.style.fontSize = size + 'px';
        let size;
        if (window.matchMedia("(min-width: 768px)").matches) {
          // PC端
          size = (document.documentElement.clientWidth / 1385) * 12;
        } else {
          // 手机端
          size = (document.documentElement.clientWidth / 360) * 12;
        }
        document.documentElement.style.fontSize = size + 'px';
      }


      // reset root font size on page show or resize
      window.addEventListener('pageshow', resetFontSize);
      window.addEventListener('resize', resetFontSize);
    })(window, document);

  },
  mounted() {
    console.log("asdfasd")
    let video = document.querySelector("#video")

    video.addEventListener("ended", () => {
      if (!this.$store.state.company_name) {
        console.log("3432423423423423423423")
        this.videoEnded()

      } else {
        this.videoDownload()
      }
    })
  },
  methods: {
    handleVisible(isVisible) {
      this.isVisible = isVisible;
    },
    handleText7Visible(isVisible) {
      this.isVisible = isVisible;
    },
    register() {
      this.$router.push("/signup")
    },
    downloadApp() {
      window.open("https://public.fjcs.xyz/%E5%85%83%E5%8F%AF%E5%8F%AF.apk")

      setTimeout(() => {
        Notify({ type: 'success', message: '已为您下载' });
      })
    },
    skip() {
      window.open("http://www.keledigit.cn/")
    },
    ToThird() {
      window.open("https://m.tb.cn/h.5fb0FpX?tk=0YpKdAk7KBE")
    },
    login() {
      this.$router.push("/login")
    },
    videoEnded() {
      Dialog.confirm({
        message: '点击立即试用申请',
        theme: 'round-button',
      }).then(() => {
        // on close
        console.log("test")
        this.register()
      });
    },
    videoDownload() {
      Dialog.confirm({
        message: '点击立即下载',
        theme: 'round-button',
      }).then(() => {
        console.log("test")
        // this.register()
        this.downloadApp()
      });
    }
  },
};
</script>

<style scoped lang="less">
.text-group_3 {
  margin: 0 auto !important;
  margin-top: 7.75rem !important;
}
</style>

<style scoped lang="less" src="./assets/index.rem.less" />


<style scoped lang="less">
.flexCenter {
  justify-content: space-around;
}

.M {
  margin-left: 9.9167rem;
}

.space-between {
  justify-content: space-between;
}

.cursorp-p {
  cursor: pointer;
}

.selno {
  user-select: none;
}

.bloom-video {
  transform: translate(4%, 0) !important;
  width: max-content !important;
  left: 0 !important;
  top: 0 !important;
}

.applyfor {
  // width: 100% !important;
  margin: 0 auto !important;
  margin-top: 5rem !important;
}

.subhead {
  margin: 0 auto !important;
  margin-top: 62rem !important;
}

#video {
  border-radius: 20px;
}

.block_5 {
  margin: 0 auto !important;
  margin-top: 16.75rem !important;
}

.text-wrapper_10 {
  left: 50% !important;
  transform: translate(-50%, 0%);
}

.text-wrapper_7 {
  margin: 0 auto !important;
  margin-top: 4.25rem !important;
}


.block_6 {
  margin: 0 auto !important;
  margin-top: 6.834rem !important;
}

.box_8 {
  margin: 0 auto !important;
  margin-top: 2.084rem;
}


.textcolor {
  color: #5573a3 !important;
}
</style>