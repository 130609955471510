import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        tk: null,
        company_name: null,
        linkman: null,
    },
    mutations: {
        increment(state, data) {
            state.tk = data;
        },
        createcompany_name(state, data) {
            state.company_name = data
        },
        createlinkman(state, data) {
            state.linkman = data
        }
    },
    actions: {
        //   incrementAsync({ commit }) {
        //     setTimeout(() => {
        //       commit('increment');
        //     }, 1000);
        //   }
    },
    plugins: [createPersistedState()]
});


export default store